<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRuleActions.EditBusinessRuleAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && businessRuleIsOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && businessRuleIsOk"
    class="form-businessruleactionsenddashboard-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "BusinessRuleActions.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="businessRuleActionData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="BusinessActionType" class="form-label"
          >{{
            $t(
              "BusinessRuleActions.BusinessActionType",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          disabled
          id="BusinessActionType"
          v-model="businessRuleActionType"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-12 mt-3">
        <label for="Description" class="form-label">{{
          $t(
            "BusinessRuleActions.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="businessRuleActionData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="2"
        ></textarea>
      </div>
      <div class="col col-md-12 mt-3">
        <label for="ResponseText" class="form-label">{{
          $t(
            "BusinessRuleActions.ResponseText",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="ResponseText"
          class="form-control"
          id="ResponseText"
          v-model="businessRuleActionData.responseText"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div style="display: none" class="form-check isForcingSave">
        <label for="isForcingSave" class="form-label">{{
          $t(
            "BusinessRuleActions.IsForcingSave",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="isForcingSave"
          v-model="businessRuleActionData.isForcingSave"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BusinessRuleActions.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="businessRuleActionData.isActive"
        />
      </div>
    </div>
    <div class="row row-cols-12 mt-3 sendDashboard">
      <div class="col col-md-6">
        <label for="NotificationType" class="form-label required">{{
          $t(
            "BusinessRuleActions.MessageTemplateType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :allowEmpty="false"
          :selectedData="selectedNotificationType"
          :isDisabled="true"
          :data="notificationTypeData"
        />
      </div>
      <div class="col col-md-6">
        <label for="NotificationSettings" class="form-label required">{{
          $t(
            "BusinessRuleActions.NotificationSettings",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :selectedData="selectedNotifierSetting"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryNotifierSettings?type={0}',
              this.notificationType
            )
          "
          @onChange="onChangeForNotifierSettings"
        />
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "BaseModelFields.CustomSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row row-cols-1 sendDashboard">
          <div class="col col-md-6 mt-3">
            <label for="SelectMessageTemplate" class="form-label required">{{
              $t(
                "BusinessRuleActions.SelectMessageTemplate",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :selectedData="selectedMessageTemplate"
              :allowEmpty="false"
              :isParameters="true"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryMessageTemplates?type={0}',
                  this.notificationType
                )
              "
              @onChange="onChangeForMessageTemplate"
            />
          </div>
          <div class="col col-md-6 mt-3">
            <label for="ReportExportType" class="form-label required">{{
              $t(
                "BusinessRuleActions.ReportExportType",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithLocalData"
              :allowEmpty="false"
              :selectedData="selectedReportExportType"
              :data="reportExportTypeData"
              @onChange="onChangeForReportExportTypeId"
            />
          </div>
          <div class="col col-md-6 mt-3">
            <label for="Subject" class="form-label required"
              >{{
                $t(
                  "BusinessRuleActions.Subject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="Subject"
              v-model="businessRuleActionData.subject"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-6 mt-3">
            <label for="DashboardId" class="form-label required"
              >{{
                $t(
                  "BusinessRuleActions.DashboardId",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="number"
              class="form-control"
              id="DashboardId"
              v-model="businessRuleActionData.dashboardId"
              :autocomplete="this.$isAutoComplete"
              required
            />
          </div>
        </div>
        <ul class="nav nav-tabs mb-2 mt-0 tabs sendNotification" role="tablist">
          <li class="nav-item toWhoTab" role="presentation">
            <button
              class="nav-link active"
              id="pills-to-who-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-to-who"
              type="button"
              role="tab"
              aria-selected="true"
            >
              {{
                $t(
                  "BusinessRuleActions.ToWho",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item ccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-cc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.CC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item bccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-bcc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.BCC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
        </ul>
        <div class="tab-content tabs sendNotification">
          <div
            class="tab-pane fade active show"
            id="pills-to-who"
            role="tabpanel"
          >
            <div class="row row-cols-12 mt-3 timeTriggeredTab">
              <div class="col col-md-12">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceTypeTo"
                  :data="sourceTypeDataForTo"
                  @onChange="onChangeForSourceTypeToWhoTimeTriggered"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideSourceTo" class="col col-md-12 mt-3 sourceTo">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedOrgUnitTo"
                  :data="userData"
                  @onChange="onChangeForSourceSelectTo"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideUserTo" class="col col-md-12 mt-3 userTo">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserTo"
                  @onMultipleSelectChange="onChangeForUserTo"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideUserGroupTo"
                class="col col-md-12 mt-3 userGroupTo"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserGroupTo"
                  @onMultipleSelectChange="onChangeForUserGroupTo"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hidePermissionGroupTo"
                class="col col-md-12 mt-3 permissionGroupTo"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedPermissionGroupTo"
                  @onMultipleSelectChange="onChangeForPermissionGroupTo"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideSourceTextTo"
                class="col col-md-12 mt-3 sourceTo"
              >
                <label for="Source" class="form-label required"
                  >{{
                    $t(
                      "BusinessRuleActions.EmailAdressInformation",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Source"
                  v-model="businessRuleActionData.recipientsToText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-cc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 timeTriggeredCc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceTypeCC"
                  :data="sourceTypeDataForCcBcc"
                  @onChange="onChangeForSourceTypeTimeTriggeredCC"
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideSourceTextCc"
                class="col col-md-12 mt-3 sourceTextCc"
              >
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="sourceTextCc"
                  v-model="businessRuleActionData.recipientsCCText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div v-if="this.hideSourceCc" class="col col-md-12 mt-3 sourceCc">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedOrgUnitCC"
                  :data="userData"
                  @onChange="onChangeForSourceSelectCC"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideUserCc" class="col col-md-12 mt-3 userCc">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserCC"
                  @onMultipleSelectChange="onChangeForUserCC"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideUserGroupCc"
                class="col col-md-12 mt-3 userGroupCc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserGroupCC"
                  @onMultipleSelectChange="onChangeForUserGroupCC"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hidePermissionGroupCc"
                class="col col-md-12 mt-3 permissionGroupCc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedPermissionGroupCC"
                  @onMultipleSelectChange="onChangeForPermissionGroupCC"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideRelatedCc" class="col col-md-12 mt-3">
                <label for="source" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :selectedData="selectedRelatedUserDataCC"
                  :allowEmpty="false"
                  :data="relatedSource"
                  @onChange="onChangeForRelatedCC"
                  openDirection="top"
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-bcc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 timeTriggeredBcc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceTypeBCC"
                  :data="sourceTypeDataForCcBcc"
                  @onChange="onChangeForSourceTypeTimeTriggeredBCC"
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideSourceTextBcc"
                class="col col-md-12 mt-3 sourceTextBcc"
              >
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="sourceTextBcc"
                  v-model="businessRuleActionData.recipientsBCCText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div
                v-if="this.hideSourceBcc"
                class="col col-md-12 mt-3 sourceBcc"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedOrgUnitBCC"
                  :data="userData"
                  @onChange="onChangeForSourceSelectBCC"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideUserBcc" class="col col-md-12 mt-3 userBcc">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserBCC"
                  @onMultipleSelectChange="onChangeForUserBCC"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideUserGroupBcc"
                class="col col-md-12 mt-3 userGroupBcc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserGroupBCC"
                  @onMultipleSelectChange="onChangeForUserGroupBCC"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hidePermissionGroupBcc"
                class="col col-md-12 mt-3 permissionGroupBcc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedPermissionGroupBCC"
                  @onMultipleSelectChange="onChangeForPermissionGroupBCC"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
            </div>
            <div v-if="this.hideRelatedBcc" class="col col-md-12 mt-3">
              <label for="source" class="form-label required">{{
                $t(
                  "BusinessRuleActions.Source",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :selectedData="selectedRelatedUserDataBCC"
                :allowEmpty="false"
                :data="relatedSource"
                @onChange="onChangeForRelatedBCC"
                openDirection="top"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";
export default {
  name: "CustomObjectEditBusinessRuleActionSendDashboard",
  data() {
    return {
      detailRequestEnd: false,
      businessRuleIsOk: false,
      selectedUserTo: [],
      selectedUserGroupTo: [],
      selectedPermissionGroupTo: [],
      selectedUserCC: [],
      selectedUserGroupCC: [],
      selectedPermissionGroupCC: [],
      selectedRelatedUserDataCC: [],
      selectedUserBCC: [],
      selectedUserGroupBCC: [],
      selectedPermissionGroupBCC: [],
      selectedRelatedUserDataBCC: [],
      selectedOrgUnitTo: [],
      selectedOrgUnitCC: [],
      selectedOrgUnitBCC: [],
      notificationTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SMS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.SystemNotification",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotificationType: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      reportExportTypeData: [
        {
          key: 1,
          value: this.$t(
            "ReportExportTypes.Pdf",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "ReportExportTypes.Excel",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "ReportExportTypes.Word",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "ReportExportTypes.Csv",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 5,
          value: this.$t(
            "ReportExportTypes.ExcelXLS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotifierSetting: [],
      selectedMessageTemplate: [],
      selectedViewFilter: [],
      selectedSourceTypeTo: [],
      selectedSourceTypeCC: [],
      selectedSourceTypeBCC: [],
      selectedReportExportType: [],
      sourceTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeDataForTo: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeDataForCcBcc: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Related",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedSourceType: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      relatedSource: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FirstManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SecondManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.ThirdManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Teammate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      userData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.UserGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.PermissionGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedUser: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedOrganizationalUnitItems: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      hideRelatedBcc: false,
      hideRelatedCc: false,
      hideSourceTextCc: true,
      hideSourceCc: false,
      hideUserCc: false,
      hideUserGroupCc: false,
      hidePermissionGroupCc: false,
      hideSourceTextBcc: true,
      hideSourceBcc: false,
      hideUserBcc: false,
      hideUserGroupBcc: false,
      hidePermissionGroupBcc: false,
      hideSourceTextTo: true,
      hideSourceTo: false,
      hideUserTo: false,
      hideUserGroupTo: false,
      hidePermissionGroupTo: false,
      errors: [],
      createRecordFieldFormulasForEdit: [],
      businessRuleActionData: {},
      model: {},
      businessRuleActionType: "",
      businessRuleTriggerTypeId: null,
      notificationType: 1,
      sourceSelect: "User",
      fieldsType: "Email|FormulaOrganizationalUnit",
      businessActionObject: [],
      detailForwardOrEditForwardType: false,
      isSuccess: false,
      multipleSelect: this.$t(
        "Fields.MultipleSelect",
        {},
        { locale: this.$store.state.activeLang }
      ),
      select: this.$t(
        "Fields.Select",
        {},
        { locale: this.$store.state.activeLang }
      ),
      table: this.$t(
        "Fields.Table",
        {},
        { locale: this.$store.state.activeLang }
      ),
    };
  },
  methods: {
    onChangeForSourceTypeToWhoTimeTriggered: function (selected) {
      this.businessRuleActionData.recipientsToInfoType = selected.key;
      this.businessRuleActionData.recipientsTo = "";
      this.businessRuleActionData.recipientsToText = "";
      this.businessRuleActionData.recipientsToOrgUnitUser = "";
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = "";

      if (selected.key == "1") {
        this.hideSourceTextTo = true;
        this.hideSourceTo = false;
        this.hideUserTo = false;
        this.hideUserGroupTo = false;
        this.hidePermissionGroupTo = false;
      } else if (selected.key == "2") {
        this.hideSourceTo = true;
        this.hideUserTo = true;
        this.hideSourceTextTo = false;
      }
    },
    onChangeForSourceTypeTimeTriggeredCC: function (selected) {
      this.businessRuleActionData.RecipientsCCInfoType = selected.key;
      this.businessRuleActionData.recipientsCC = "";
      this.businessRuleActionData.recipientsCCText = "";
      this.businessRuleActionData.recipientsCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = "";
      this.businessRuleActionData.recipientsCCFromRelationTo = "";
      if (selected.key == "1") {
        this.hideSourceCc = false;
        this.hideSourceTextCc = true;
        this.hideUserCc = false;
        this.hideUserGroupCc = false;
        this.hidePermissionGroupTo = false;
        this.hideRelatedCc = false;
      } else if (selected.key == "2") {
        this.hideSourceCc = true;
        this.hideUserCc = true;
        this.hideSourceTextCc = false;
        this.hideRelatedCc = false;
      } else if (selected.key == "4") {
        this.hideSourceCc = false;
        this.hideSourceTextCc = false;
        this.hideUserCc = false;
        this.hideUserGroupCc = false;
        this.hidePermissionGroupCc = false;
        this.hideRelatedCc = true;
      }
    },
    onChangeForSourceTypeTimeTriggeredBCC: function (selected) {
      this.businessRuleActionData.RecipientsBCCInfoType = selected.key;
      this.businessRuleActionData.recipientsBCC = "";
      this.businessRuleActionData.recipientsBCCText = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = "";
      this.businessRuleActionData.recipientsBCCFromRelationTo = "";
      if (selected.key == "1") {
        this.hideSourceBcc = false;
        this.hideSourceTextBcc = true;
        this.hideUserBcc = false;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = false;
        this.hideRelatedBcc = false;
      } else if (selected.key == "2") {
        this.hideSourceBcc = true;
        this.hideUserBcc = true;
        this.hideSourceTextBcc = false;
        this.hideRelatedBcc = false;
      } else if (selected.key == "4") {
        this.hideSourceTextBcc = false;
        this.hideSourceBcc = false;
        this.hideUserBcc = false;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = false;
        this.hideRelatedBcc = true;
      }
    },
    onChangeForSourceSelectCC: function (selected) {
      this.businessRuleActionData.recipientsCCOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.hideUserCc = true;
        this.hidePermissionGroupCc = false;
        this.hideUserGroupCc = false;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        this.hideUserCc = false;
        this.hideUserGroupCc = true;
        this.hidePermissionGroupCc = false;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        this.hideUserCc = false;
        this.hideUserGroupCc = false;
        this.hidePermissionGroupCc = true;
      }
    },
    onChangeForSourceSelectBCC: function (selected) {
      this.businessRuleActionData.recipientsBCCOrganizationalUnit =
        selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.hideUserBcc = true;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = false;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        this.hideUserBcc = false;
        this.hideUserGroupBcc = true;
        this.hidePermissionGroupBcc = false;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        this.hideUserBcc = false;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = true;
      }
    },
    onChangeForSourceSelectTo: function (selected) {
      this.businessRuleActionData.recipientsToOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.hideUserTo = true;
        this.hideUserGroupTo = false;
        this.hidePermissionGroupTo = false;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        this.hideUserTo = false;
        this.hideUserGroupTo = true;
        this.hidePermissionGroupTo = false;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        this.hideUserTo = false;
        this.hideUserGroupTo = false;
        this.hidePermissionGroupTo = true;
      }
    },
    onChangeForUserTo: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUser = ids;
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = "";
    },
    onChangeForUserGroupTo: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUser = "";
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = "";
    },
    onChangeForPermissionGroupTo: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUser = "";
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = ids;
    },
    onChangeForUserCC: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUser = ids;
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = "";
    },
    onChangeForUserGroupCC: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = "";
    },
    onChangeForPermissionGroupCC: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = ids;
    },
    onChangeForUserBCC: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUser = ids;
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = "";
    },
    onChangeForUserGroupBCC: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = "";
    },
    onChangeForPermissionGroupBCC: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = ids;
    },
    onChangeForRelatedCC: function (selected) {
      this.businessRuleActionData.recipientsCCFromRelationTo =
        selected.key.toString();
    },
    onChangeForRelatedBCC: function (selected) {
      this.businessRuleActionData.recipientsBCCFromRelationTo =
        selected.key.toString();
    },
    getCustomObjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.businessActionObject = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeForNotifierSettings: function (selected) {
      this.businessRuleActionData.NotifierSettingPublicId = selected.key;
      this.businessRuleActionData.NotifierSettingName = selected.value;
    },
    onChangeForMessageTemplate: function (selected) {
      this.businessRuleActionData.MessageTemplatePublicId = selected.key;
    },
    onChangeForReportExportTypeId: function (selected) {
      this.businessRuleActionData.ReportExportTypeId = selected.key;
    },
    onClear() {
      this.businessRuleActionData = {
        customObjectPublicId: this.$route.params.customObjectId,
        businessRulePublicId: this.$route.params.businessRuleId,
        name: "",
        formula: "",
        errorText: "",
        isShowMessageInModal: false,
        isActive: true,
      };

      $(".form-businessruleactionsendDashboard-new").removeClass(
        "was-validated"
      );
    },
    onSubmit(submitType, buttonName) {
      // var form = $(".form-businessruleactionsendXtraReport-new");
      // form.addClass("was-validated");
      // if (
      //   !this.$root.brActionValidity(form[0]) ||
      //   form.find(".ms-invalid:visible").length > 0
      // ) {
      //   return;
      // }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleActionUpdateActionSendDashboard", {
          ...this.businessRuleActionData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/BusinessRuleActions/" +
                this.$route.params.customObjectId +
                "&businessRuleId=" +
                this.$route.params.businessRuleId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getBusinessRuleActionData() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-BusinessRuleActionGet?id={0}&coId={1}&businessRuleId={2}",
            this.$route.params.businessRuleActionId,
            this.$route.params.customObjectId,
            this.$route.params.businessRuleId
          )
        )
        .then((response) => {
          this.businessRuleIsOk = response.data.isOk;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            this.model = response.data.businessRuleAction;
            this.businessRuleActionData.businessRulePublicId =
              this.model.businessRulePublicId;
            this.businessRuleActionData.customObjectPublicId =
              this.model.customObjectPublicId;
            this.businessRuleActionData.publicId = this.model.publicId;
            this.businessRuleActionData.name = this.model.name;
            this.businessRuleActionData.businessRuleName =
              this.model.businessRuleName;
            this.businessRuleActionData.businessRulePublicId =
              this.model.businessRulePublicId;
            this.businessRuleActionData.description = this.model.description;
            this.businessRuleActionData.responseText = this.model.responseText;
            this.businessRuleActionData.isActive = this.model.isActive;
            this.businessRuleActionData.IsRunOnceForEachRecord =
              this.model.IsRunOnceForEachRecord;
            this.businessRuleActionData.IsSynchAction =
              this.model.IsSynchAction;
            this.businessRuleActionData.subject = this.model.subject;
            this.businessRuleActionData.dashboardId = this.model.xtraReportId;

            this.businessRuleActionData.isActive = this.model.isActive;
            this.businessRuleActionData.isNotSendEmailIfThereIsNoRecord =
              this.model.isNotSendEmailIfThereIsNoRecord;
            this.businessRuleActionData.isRunSeparatelyForEachUser =
              this.model.isRunSeparatelyForEachUser;

            this.businessRuleActionData.BusinessRuleActionType =
              this.model.businessRuleActionType;
            this.businessRuleActionData.BusinessRuleActionTypeId =
              this.model.businessRuleActionTypeId;
            this.businessRuleActionData.CustomObjectKey =
              this.model.customObjectKey;
            this.businessRuleActionData.CustomObjectName =
              this.model.customObjectName;

            this.businessRuleActionData.messageTemplateTypeId =
              this.model.notifierType.value;
            this.businessRuleActionData.messageTemplateTypeName =
              this.model.notifierType.displayName;

            this.businessRuleActionData.messageTemplateName =
              this.model.messageTemplateName;
            this.businessRuleActionData.messageTemplatePublicId =
              this.model.messageTemplatePublicId;

            this.businessRuleActionData.notifierSettingName =
              this.model.notifierSettingName;
            this.businessRuleActionData.notifierSettingPublicId =
              this.model.notifierSettingPublicId;

            this.businessRuleActionData.viewFilterName =
              this.model.viewFilterName;
            this.businessRuleActionData.viewFilterPublicId =
              this.model.viewFilterPublicId;

            this.selectedMessageTemplate.push({
              key: this.model.messageTemplatePublicId,
              value: this.model.messageTemplateName,
            });

            this.selectedNotifierSetting.push({
              key: this.model.notifierSettingPublicId,
              value: this.model.notifierSettingName,
            });
            this.selectedViewFilter.push({
              key: this.model.viewFilterPublicId,
              value: this.model.viewFilterName,
            });
            this.businessRuleActionData.ReportExportTypeId =
              this.model.reportExportType.value;

            var selectedExportType = this.reportExportTypeData.find(
              (f) => f.key == this.businessRuleActionData.ReportExportTypeId
            );

            this.selectedReportExportType.push(selectedExportType);

            this.businessRuleActionData.recipientsToInfoType =
              this.model.recipientsToInfoType.value;
            this.businessRuleActionData.recipientsCCInfoType =
              this.model.recipientsCCInfoType.value;
            this.businessRuleActionData.recipientsBCCInfoType =
              this.model.recipientsBCCInfoType.value;
            var sourceTypeTo = this.sourceTypeDataForCcBcc.find(
                (f) => f.key == this.businessRuleActionData.recipientsToInfoType
              ),
              sourceTypeCC = this.sourceTypeDataForCcBcc.find(
                (f) => f.key == this.businessRuleActionData.recipientsCCInfoType
              ),
              sourceTypeBCC = this.sourceTypeDataForCcBcc.find(
                (f) =>
                  f.key == this.businessRuleActionData.recipientsBCCInfoType
              );

            this.selectedSourceTypeTo.push({
              key: sourceTypeTo.key,
              value: sourceTypeTo.value,
            });

            this.selectedSourceTypeCC.push({
              key: sourceTypeCC.key,
              value: sourceTypeCC.value,
            });

            this.selectedSourceTypeBCC.push({
              key: sourceTypeBCC.key,
              value: sourceTypeBCC.value,
            });
            this.onChangeForSourceTypeToWhoTimeTriggered(sourceTypeTo);
            this.onChangeForSourceTypeTimeTriggeredCC(sourceTypeCC);
            this.onChangeForSourceTypeTimeTriggeredBCC(sourceTypeBCC);

            this.businessRuleActionData.recipientsTo = this.model.recipientsTo;
            this.businessRuleActionData.recipientsToName =
              this.model.recipientsToName;
            this.businessRuleActionData.recipientsCC = this.model.recipientsCC;
            this.businessRuleActionData.recipientsCCName =
              this.model.recipientsCCName;
            this.businessRuleActionData.recipientsBCC =
              this.model.recipientsBCC;
            this.businessRuleActionData.recipientsBCCName =
              this.model.recipientsBCCName;

            this.businessRuleActionData.recipientsToOrganizationalUnit =
              this.model.recipientsToOrganizationalUnit;
            this.businessRuleActionData.recipientsCCOrganizationalUnit =
              this.model.recipientsCCOrganizationalUnit;
            this.businessRuleActionData.recipientsBCCOrganizationalUnit =
              this.model.recipientsBCCOrganizationalUnit;

            if (sourceTypeTo.key == 2) {
              this.onChangeForSourceSelectTo({
                key: this.businessRuleActionData.recipientsToOrganizationalUnit,
              });
            }
            if (sourceTypeCC.key == 2) {
              this.onChangeForSourceSelectCC({
                key: this.businessRuleActionData.recipientsCCOrganizationalUnit,
              });
            }
            if (sourceTypeBCC.key == 2) {
              this.onChangeForSourceSelectBCC({
                key: this.businessRuleActionData
                  .recipientsBCCOrganizationalUnit,
              });
            }
            if (
              !String.isNullOrWhiteSpace(
                this.businessRuleActionData.recipientsToName
              ) ||
              !String.isNullOrWhiteSpace(
                this.businessRuleActionData.recipientsTo
              )
            ) {
              this.SetRecipientValueTo(
                sourceTypeTo.key,
                this.businessRuleActionData.recipientsToOrganizationalUnit
              );
            }
            if (
              !String.isNullOrWhiteSpace(
                this.businessRuleActionData.recipientsCCName
              ) ||
              !String.isNullOrWhiteSpace(
                this.businessRuleActionData.recipientsCC
              )
            ) {
              this.SetRecipientValueCC(
                sourceTypeCC.key,
                this.businessRuleActionData.recipientsCCOrganizationalUnit
              );
            }

            if (
              !String.isNullOrWhiteSpace(
                this.businessRuleActionData.recipientsBCC
              ) ||
              !String.isNullOrWhiteSpace(
                this.businessRuleActionData.recipientsBCCName
              )
            ) {
              this.SetRecipientValueBCC(
                sourceTypeBCC.key,
                this.businessRuleActionData.recipientsBCCOrganizationalUnit
              );
            }
          } else {
            this.errors.push(response.data.message);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    SetRecipientValueTo(sourceType, orgUnitType) {
      switch (sourceType) {
        case 1:
          this.businessRuleActionData.recipientsToText =
            this.businessRuleActionData.recipientsTo;
          this.businessRuleActionData.recipientsToName =
            this.businessRuleActionData.recipientsTo;
          break;
        case 2:
          var selectedOrgUnitItemTo = this.userData.find(
            (f) =>
              f.key ==
              this.businessRuleActionData.recipientsToOrganizationalUnit
          );
          this.selectedOrgUnitTo.push({
            key: selectedOrgUnitItemTo.key,
            value: selectedOrgUnitItemTo.value,
          });
          this.onChangeForSourceSelectTo(selectedOrgUnitItemTo);
          var recipientsToOrgUnit =
            this.businessRuleActionData.recipientsTo.split(
              this.$systemSeparator
            );
          var recipientsToOrgUnitNames =
            this.businessRuleActionData.recipientsToName.split(
              this.$systemSeparator
            );
          if (orgUnitType == 1) {
            recipientsToOrgUnit.forEach((v, i) => {
              this.selectedUserTo.push({
                key: v,
                value: recipientsToOrgUnitNames[i],
              });
            });
            this.onChangeForUserTo(this.businessRuleActionData.recipientsTo);
            // this.onChangeForUserToNames(
            //   this.businessRuleActionData.recipientsToName
            // );
          } else if (orgUnitType == 2) {
            recipientsToOrgUnit.forEach((v, i) => {
              this.selectedUserGroupTo.push({
                key: v,
                value: recipientsToOrgUnitNames[i],
              });
            });
            this.onChangeForUserGroupTo(
              this.businessRuleActionData.recipientsTo
            );
            // this.onChangeForUserGroupToNames(
            //   this.businessRuleActionData.recipientsToName
            // );
          } else if (orgUnitType == 3) {
            recipientsToOrgUnit.forEach((v, i) => {
              this.selectedPermissionGroupTo.push({
                key: v,
                value: recipientsToOrgUnitNames[i],
              });
            });
            this.onChangeForPermissionGroupTo(
              this.businessRuleActionData.recipientsTo
            );
            // this.onChangeForPermissionGroupToNames(
            //   this.businessRuleActionData.recipientsToName
            // );
          }
          break;
        default:
          break;
      }
    },
    SetRecipientValueCC(sourceType, orgUnitType) {
      switch (sourceType) {
        case 1:
          this.businessRuleActionData.recipientsCCText =
            this.businessRuleActionData.recipientsCC;
          this.businessRuleActionData.recipientsCCName =
            this.businessRuleActionData.recipientsCC;
          break;
        case 2:
          var selectedOrgUnitItemCC = this.userData.find(
            (f) =>
              f.key ==
              this.businessRuleActionData.recipientsCCOrganizationalUnit
          );
          this.selectedOrgUnitCC.push({
            key: selectedOrgUnitItemCC.key,
            value: selectedOrgUnitItemCC.value,
          });
          var recipientsCCOrgUnit =
            this.businessRuleActionData.recipientsCC.split(
              this.$systemSeparator
            );
          var recipientsCCOrgUnitNames =
            this.businessRuleActionData.recipientsCCName.split(
              this.$systemSeparator
            );
          if (orgUnitType == 1) {
            recipientsCCOrgUnit.forEach((v, i) => {
              this.selectedUserCC.push({
                key: v,
                value: recipientsCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserCC(this.businessRuleActionData.recipientsCC);
            // this.onChangeForUserCCNames(
            //   this.businessRuleActionData.recipientsCCName
            // );
          } else if (orgUnitType == 2) {
            recipientsCCOrgUnit.forEach((v, i) => {
              this.selectedUserGroupCC.push({
                key: v,
                value: recipientsCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserGroupCC(
              this.businessRuleActionData.recipientsCC
            );
            // this.onChangeForUserGroupCCNames(
            //   this.businessRuleActionData.recipientsCCName
            // );
          } else if (orgUnitType == 3) {
            recipientsCCOrgUnit.forEach((v, i) => {
              this.selectedPermissionGroupCC.push({
                key: v,
                value: recipientsCCOrgUnitNames[i],
              });
            });
            this.onChangeForPermissionGroupCC(
              this.businessRuleActionData.recipientsCC
            );
            // this.onChangeForPermissionGroupCCNames(
            //   this.businessRuleActionData.recipientsCCName
            // );
          }
          break;
        case 4:
          var selectedRelated = this.relatedSource.find(
            (f) => f.key == parseInt(this.businessRuleActionData.recipientsCC)
          );
          this.selectedRelatedUserDataCC.push(selectedRelated);
          this.onChangeForRelatedCC(selectedRelated);
          break;
        default:
          break;
      }
    },
    SetRecipientValueBCC(sourceType, orgUnitType) {
      switch (sourceType) {
        case 1:
          this.businessRuleActionData.recipientsBCCText =
            this.businessRuleActionData.recipientsBCC;
          this.businessRuleActionData.recipientsBCCName =
            this.businessRuleActionData.recipientsBCC;
          break;
        case 2:
          var selectedOrgUnitItemBCC = this.userData.find(
            (f) =>
              f.key ==
              this.businessRuleActionData.recipientsBCCOrganizationalUnit
          );
          this.selectedOrgUnitBCC.push({
            key: selectedOrgUnitItemBCC.key,
            value: selectedOrgUnitItemBCC.value,
          });
          // this.onChangeForSourceSelectBCC(selectedOrgUnitItemBCC);
          var recipientsBCCOrgUnit =
            this.businessRuleActionData.recipientsBCC.split(
              this.$systemSeparator
            );
          var recipientsBCCOrgUnitNames =
            this.businessRuleActionData.recipientsBCCName.split(
              this.$systemSeparator
            );
          if (orgUnitType == 1) {
            recipientsBCCOrgUnit.forEach((v, i) => {
              this.selectedUserBCC.push({
                key: v,
                value: recipientsBCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserBCC(this.businessRuleActionData.recipientsBCC);
            // this.onChangeForUserBCCNames(
            //   this.businessRuleActionData.recipientsBCCName
            // );
          } else if (orgUnitType == 2) {
            recipientsBCCOrgUnit.forEach((v, i) => {
              this.selectedUserGroupBCC.push({
                key: v,
                value: recipientsBCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserGroupBCC(
              this.businessRuleActionData.recipientsBCC
            );
            // this.onChangeForUserGroupBCCNames(
            //   this.businessRuleActionData.recipientsBCCName
            // );
          } else if (orgUnitType == 3) {
            recipientsBCCOrgUnit.forEach((v, i) => {
              this.selectedPermissionGroupBCC.push({
                key: v,
                value: recipientsBCCOrgUnitNames[i],
              });
            });
            this.onChangeForPermissionGroupBCC(
              this.businessRuleActionData.recipientsBCC
            );
            // this.onChangeForPermissionGroupBCCNames(
            //   this.businessRuleActionData.recipientsBCCName
            // );
          }
          break;
        case 4:
          var selectedRelated = this.relatedSource.find(
            (f) => f.key == parseInt(this.businessRuleActionData.recipientsBCC)
          );
          this.selectedRelatedUserDataBCC.push(selectedRelated);
          this.onChangeForRelatedBCC(selectedRelated);
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.getCustomObjectDetail();
    this.getBusinessRuleActionData();
    this.businessRuleTriggerTypeId = localStorage.getItem(
      "businessRuleTriggerTypeId"
    );
    this.businessRuleActionType = localStorage.getItem(
      "businessRuleActionType"
    );
  },
};
</script>
